/* global config, moment */

'use strict';

angular.module('managerApp').controller('CertificatSituationController', (
        $scope, $location, $routeParams, $mdDialog,
        toast, procedureService, procedureTools) => {

    $scope.messages = procedureTools.messages;
    $scope.config = config;

    // init 
    $scope._type = 'certificat-situation-administrative';

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            if ($scope.pagination.filters.date) {
                let date = '07/02/2032'; //$scope.pagination.filters.date;
                date = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                $scope.pagination.filters.date = date;
            }
            getProcedures();
        }
    };

    $scope.procedureId = $routeParams.id;
    $scope.isItem = typeof $scope.procedureId !== 'undefined';

    if ($scope.isItem) {
        getProcedure($scope.procedureId);
    } else {
        getProcedures();
    }

    function getProcedure(id) {
        procedureService.get({
            id: id,
            type: $scope._type
        }).then((data) => {
            $scope.procedure = data;
            $scope.client = $scope.procedure.Client;
            $scope.item = $scope.procedure.CertificatSituation;
            $scope.vehicle = $scope.item.Vehicle;
            $scope.procedure.origin = procedureTools.getOrigin($scope.procedure);
            procedureTools.initDocuments($scope);
            $scope.$apply();
        });
    }

    function getProcedures() {
        let params = {
            type: $scope._type
        };
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        procedureService.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.getVehicleInfos = (vehicleRegNum = '') => {
        procedureTools.getVehicleInfos(vehicleRegNum).then((infos) => {
            let s = '<div class="no-padding-top padding p20">';
            s += '<h3>' + vehicleRegNum + '</h3><hr>';
            for (let i in infos) {
                let v = infos[i];
                if (v.label !== '') {
                    s += '<p>' + '<span style="display:inline-block;width:260px;font-weight:700">' +
                            v.label + '</span> ' + v.value + '</p>';
                }
            }
            s += '</div>';
            $mdDialog.show({
                controller: function () {},
                template: s,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        }).catch((err) => {
            console.log(err);
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', false, 7500);
        });
    };

});